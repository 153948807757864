<template>
    <Dropdown ref="buscador" id="buscador" filter editable v-model="texto_buscador"
        :filterFields="['codigo', 'fecha', 'estado', 'nombre','direccion', 'poblacion','provincia']"
        :options="servicios" 
        optionLabel="nombre" 
        emptyMessage="No se han encontrado expedientes (mín 3 char)"
        emptyFilterMessage="No se han encontrado expedientes (mín 3 char)" 
        placeholder="Buscador Expedientes"
        class="buscador w-full md:w-14rem" @input="onInput" @update:modelValue="handleModelUpdate">
        <template #option="slotProps">
            <div @click.stop.prevent="selectOption(slotProps.option)" class="dropdown-option">
                <b>{{ slotProps.option.codigo}}</b>&nbsp;||&nbsp;
                {{slotProps.option.fecha}}&nbsp;||&nbsp;
                {{slotProps.option.estado}}&nbsp;||&nbsp;
                {{slotProps.option.nombre}}&nbsp;||&nbsp;
                {{slotProps.option.direccion}}&nbsp;||&nbsp;
                {{slotProps.option.poblacion}}&nbsp;||&nbsp;
                {{slotProps.option.provincia}}
            </div>
        </template>
    </Dropdown>
</template>
<script>
import Dropdown from 'primevue/dropdown';
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    components: {
        Dropdown,
    },
    data() {
        return {
            servicios: [],
            texto_buscador: ''
        }
    },
    methods: {
        async buscarservicios(buscador) {
            try {
                const api = new PwgsApi();
                buscador = encodeURIComponent(buscador);
                const response = await api.get('listados-servicios/buscador?buscador=' + buscador);
                this.servicios = response.datos || [];
                this.$refs.buscador.show();
            } catch (error) {
                console.error('Error al buscar servicios:', error);
            }
        },
        onInput() {
            if (this.texto_buscador && this.texto_buscador.length > 2) {
                this.buscarservicios(this.texto_buscador);
            }else{
                this.servicios = [];
            }
        },
        iraservicio(idruta,codigo) {
            localStorage.setItem('nombreservicio' + idruta, codigo);

            this.$router.push('/servicios/' + idruta);     
              
        },
        selectOption(option) {
            this.texto_buscador = ''; // Limpia el campo de búsqueda
            this.iraservicio(option.idservicios,option.codigo);
            this.$refs.buscador.hide();
        },
        handleModelUpdate() {
            // Esta función ahora maneja las actualizaciones y decide no actualizar texto_buscador.
        }
    },
    mounted() {
    }
})
</script>
<style>
.p-dropdown-items>.p-dropdown-item:nth-child(even) {
    background: #eeeaea !important;
}
.p-dropdown-items>.p-dropdown-item:hover {
    background-color: #067eff !important;
    color:white !important;
}
.buscador.p-dropdown-panel{
    left: 200px !important;
}
.dropdown-option {
    max-width: 65vw; /* 65% del ancho de la ventana */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>